// consumer_web_policyConfig_getFlightPolicyList 获取机票政策列表
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://38568z29x3.zicp.vip/doc.html#/yinzhilv-tmc/t-bd-policy-config-controller/getFlightPolicyListUsingPOST
const consumer_web_policyConfig_getFlightPolicyList = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/web/policyConfig/getFlightPolicyList',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_web_policyConfig_getFlightPolicyList;