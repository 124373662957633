import consumer_web_policyConfig_getFlightPolicyList from '@/lib/data-service/haolv-default/consumer_web_policyConfig_getFlightPolicyList'
import consumer_web_policyConfig_delTravelPolicyByBusinessType from '@/lib/data-service/haolv-default/consumer_web_policyConfig_delTravelPolicyByBusinessType'
export default {
    data() {
        return {
            loading: false,
            policyList: [],
        }
    },
    components: {
    },
    created() {
    },
    mounted() {

    },
    activated() {
        this.getPolicyList()
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        getPolicyList() {
            this.loading = true
            consumer_web_policyConfig_getFlightPolicyList().then(res => {
                this.loading = false
                this.policyList = res.datas
            }).catch(() => {
                this.loading = false
                this.policyList = []
            })
        },
        edit(val) {
            this.$router.push({
                name: 'admin-evection-flight-policy-edit',
                query: {
                    id: val.id,
                    type: 'edit'
                }
            })
        },
        del(val) {
            this.$confirm('确定删除此政策吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                consumer_web_policyConfig_delTravelPolicyByBusinessType({ id: val.id, businessType: 1 }).then(res => {
                    this.getPolicyList()
                })
            }).catch(() => {
            });
        },
        addPolicy() {
            this.$router.push({
                name: 'admin-evection-flight-policy-edit',
                query: {
                    id: '',
                    type: 'add'
                }
            })
        },
    }
}